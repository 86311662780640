import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

type BaseResponseDto<Type> = {
  data: Type,
  correlationId: string
}

export default BaseResponseDto;

export function isFetchBaseQueryError<T>(response: T | FetchBaseQueryError): response is FetchBaseQueryError {
  return 'data' in (response as FetchBaseQueryError);
}

export function isBaseResponseDto<T>(response: T | BaseResponseDto<T>): response is BaseResponseDto<T> {
  return (response as BaseResponseDto<T>).correlationId !== undefined && (response as BaseResponseDto<T>).correlationId !== '';
}

/**
 * Gets correlation id if it is a BaseResponseDto.
 * @param response object to check or rather get correlation id from.
 * @returns correlation id or undefined.
 */
export function getCorrelationIdIfBaseResponseDto<T>(response: T | BaseResponseDto<T>): string | undefined {
  if (!response || !isBaseResponseDto(response)) {
    return undefined;
  }

  return response.correlationId;
}

/**
 * Use function to get correlation id from an error received by rtk api hook.
 * @param error the error from the rtk hook, which is the second parameter.
 * @param defaultValue value, which should be returned if no correlation id is available. Default is undefined.
 * @returns found correlation id or defaultValue.
 */
export function getCorrelationIdFromRtkError(error: SerializedError | FetchBaseQueryError | undefined, defaultValue: string | undefined = undefined): string | undefined {
  if (!error) {
    return defaultValue;
  }

  if (isFetchBaseQueryError(error) && isBaseResponseDto(error.data)) {
    return error.data.correlationId;
  }

  return defaultValue;
}
