import { AxiosResponse } from 'axios';

export type HttpStatusCodeRange<T> = {
  start: number
  end: number
  eventFunction: ((response: AxiosResponse<T>) => void)
}

export type StatusHashmap<T> = {
  [status: number]: ((response: AxiosResponse<T>) => void)
}

export class HttpStatusCodeEventType<T> {
  public statusCodes: StatusHashmap<T>;

  public defaultEvent: ((response: AxiosResponse<T>) => void);

  constructor(defaultEvent: ((response: AxiosResponse<T>) => void), statusCodes: StatusHashmap<T> | null = null, statusCodesRanges: Array<HttpStatusCodeRange<T>> | null = null) {
    this.statusCodes = {};
    if (statusCodes) {
      Object.keys(statusCodes).forEach((key: string) => {
        const currentNumber = Number(key);
        if (Number.isNaN(currentNumber)) { return; }

        this.statusCodes[currentNumber] = statusCodes[currentNumber];
      });
    }

    if (statusCodesRanges && statusCodesRanges.length) {
      statusCodesRanges.forEach((statusCodeRange: HttpStatusCodeRange<T>) => {
        this.setRange(statusCodeRange.start, statusCodeRange.end, statusCodeRange.eventFunction);
      });
    }

    this.defaultEvent = defaultEvent;
  }

  public setRange(start: number, end: number, eventFunction: ((response: AxiosResponse<T>) => void)): void {
    if (!this.statusCodes) { this.statusCodes = {}; }

    let startValue; let
      endValue;
    if (start > end) {
      startValue = Math.ceil(end);
      endValue = Math.ceil(start);
    } else {
      startValue = Math.ceil(start);
      endValue = Math.ceil(end);
    }

    for (let currentStatus = startValue; currentStatus <= endValue; currentStatus += 1) {
      this.statusCodes[currentStatus] = eventFunction;
    }
  }

  public getEventFunction(statusCode: number): ((response: AxiosResponse<T>) => void) {
    if (!this.statusCodes) { return this.defaultEvent; }

    const declaredFunction = this.statusCodes[statusCode];
    if (declaredFunction) { return declaredFunction; }

    return this.defaultEvent;
  }
}
