const documentsPageDatacenterIdParameter = ':datacenterId';
const documentsPageDatacenterEntryIdParameter = ':datacenterEntryId';

const RoutesConstants = {
  routePath: {
    datacenterSearchPage: '/',
    documentsPage: `/testDocumentsPage/${documentsPageDatacenterIdParameter}`,
    // testPage: `/testPage/${documentsPageDatacenterIdParameter}`,
    // testPage2: `/testPage/${documentsPageDatacenterIdParameter}/${documentsPageDatacenterEntryIdParameter}`,
    testPage: `/${documentsPageDatacenterIdParameter}`,
    testPage2: `/${documentsPageDatacenterIdParameter}/${documentsPageDatacenterEntryIdParameter}`,
    notFoundPage: '*',
  },
};

export default RoutesConstants;

export function getDocumentsPageUrlWithParameter(datacenterId: string): string {
  return RoutesConstants.routePath.testPage.replace(documentsPageDatacenterIdParameter, datacenterId);
}

export function getDocumentsPageUrlWithParameters(datacenterId: string, datacenterEntryId: string): string {
  return RoutesConstants.routePath.testPage2.replace(documentsPageDatacenterIdParameter, datacenterId)
    .replace(documentsPageDatacenterEntryIdParameter, datacenterEntryId);
}
