import {
  Theme, createTheme, ThemeOptions, PaletteColor, PaletteColorOptions,
} from '@mui/material';
import { darkTheme, lightTheme } from './MaterialUiDefaultTheme';

export function getTheme(dark: boolean): Theme {
  const theme: ThemeOptions = dark ? darkTheme : lightTheme;
  return createTheme({ ...theme });
}

declare module '@mui/material/styles' {
  interface Palette {
    header: PaletteColor
  }
  interface PaletteOptions {
    header: PaletteColorOptions
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    compeonLogo: {
      color: React.CSSProperties['color']
    },

    chonky: {
      colors: {
        debugRed: React.CSSProperties['color']
        debugBlue: React.CSSProperties['color']
        debugGreen: React.CSSProperties['color']
        debugPurple: React.CSSProperties['color']
        debugYellow: React.CSSProperties['color']
        datacenterBlue: React.CSSProperties['color']

        textActive: React.CSSProperties['color']
      }
      fontSizes: {
        rootPrimary: React.CSSProperties['fontSize']
      }
      margins: {
        rootLayoutMargin: React.CSSProperties['margin']
      }
      toolbar: {
        size: number
        lineHeight: React.CSSProperties['lineHeight'] // `px` suffix is required for `line-height` fields to work
        fontSize: React.CSSProperties['fontSize']
        buttonRadius: number
      }
      dnd: {
        canDropColor: React.CSSProperties['color']
        cannotDropColor: React.CSSProperties['color']
        canDropMask: React.CSSProperties['color']
        cannotDropMask: React.CSSProperties['color']
        fileListCanDropMaskOne: React.CSSProperties['color']
        fileListCanDropMaskTwo: React.CSSProperties['color']
        fileListCannotDropMaskOne: React.CSSProperties['color']
        fileListCannotDropMaskTwo: React.CSSProperties['color']
      }
      dragLayer: {
        border: React.CSSProperties['border']
        padding: React.CSSProperties['padding']
        borderRadius: React.CSSProperties['borderRadius']
      }
      fileList: {
        desktopGridGutter: number
        mobileGridGutter: number
      }
      gridFileEntry: {
        childrenCountSize: React.CSSProperties['fontSize']
        iconColorFocused: React.CSSProperties['color']
        iconSize: string
        iconColor: React.CSSProperties['color']
        borderRadius: number
        fontSize: number

        fileColorTint: React.CSSProperties['color']
        folderBackColorTint: React.CSSProperties['color']
        folderFrontColorTint: React.CSSProperties['color']
      }
      listFileEntry: {
        propertyFontSize: number
        iconFontSize: React.CSSProperties['fontSize']
        iconBorderRadius: number
        fontSize: number
        propertyHeaderFontSize: number
      }
    }
  }

  interface ThemeOptions {
    compeonLogo: {
      color: React.CSSProperties['color']
    },

    chonky: {
      colors: {
        debugRed: React.CSSProperties['color']
        debugBlue: React.CSSProperties['color']
        debugGreen: React.CSSProperties['color']
        debugPurple: React.CSSProperties['color']
        debugYellow: React.CSSProperties['color']
        datacenterBlue: React.CSSProperties['color']

        textActive: React.CSSProperties['color']
      }
      fontSizes: {
        rootPrimary: React.CSSProperties['fontSize']
      }
      margins: {
        rootLayoutMargin: React.CSSProperties['margin']
      }
      toolbar: {
        size: number
        lineHeight: React.CSSProperties['lineHeight'] // `px` suffix is required for `line-height` fields to work
        fontSize: React.CSSProperties['fontSize']
        buttonRadius: number
      }
      dnd: {
        canDropColor: React.CSSProperties['color']
        cannotDropColor: React.CSSProperties['color']
        canDropMask: React.CSSProperties['color']
        cannotDropMask: React.CSSProperties['color']
        fileListCanDropMaskOne: React.CSSProperties['color']
        fileListCanDropMaskTwo: React.CSSProperties['color']
        fileListCannotDropMaskOne: React.CSSProperties['color']
        fileListCannotDropMaskTwo: React.CSSProperties['color']
      }
      dragLayer: {
        border: React.CSSProperties['border']
        padding: React.CSSProperties['padding']
        borderRadius: React.CSSProperties['borderRadius']
      }
      fileList: {
        desktopGridGutter: number
        mobileGridGutter: number
      }
      gridFileEntry: {
        childrenCountSize: React.CSSProperties['fontSize']
        iconColorFocused: React.CSSProperties['color']
        iconSize: string
        iconColor: React.CSSProperties['color']
        borderRadius: number
        fontSize: number

        fileColorTint: React.CSSProperties['color']
        folderBackColorTint: React.CSSProperties['color']
        folderFrontColorTint: React.CSSProperties['color']
      }
      listFileEntry: {
        propertyFontSize: number
        iconFontSize: React.CSSProperties['fontSize']
        iconBorderRadius: number
        fontSize: number
        propertyHeaderFontSize: number
      }
    }
  }
}
