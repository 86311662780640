import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Settings } from '../../domain/application';

export type SettingsDrawerProps = {
  settingsOpen: boolean,
  onSettingsClose: () => void,
  settings: Settings,
  onSettingsChange: (changedSettings: Settings) => void
}

const modeSettingValues = {
  dark: 'dark',
  light: 'light',
};

const languageSettingValues = {
  english: 'en-EN',
  german: 'de-DE',
};

const SettingsDrawer = ({
  settingsOpen, onSettingsClose, settings, onSettingsChange,
}: SettingsDrawerProps): JSX.Element => {
  const { i18n, t } = useTranslation('App', { useSuspense: false });

  const onModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    const updatedSettings: Settings = {
      ...settings,
      darkMode: newAlignment === modeSettingValues.dark,
    };

    onSettingsChange(updatedSettings);
  };

  const onLanguageChanged = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string | null,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    i18n.changeLanguage(newLanguage ?? undefined);
  };

  const darwerList = (): JSX.Element => (
    <Box
      role="presentation"
      onClick={onSettingsClose}
      onKeyDown={onSettingsClose}
    >
      <Box
        sx={{
          display: 'flex',
          p: 2,
        }}
      >
        <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
          {t('App:settings.title')}
        </Typography>
        <Button
          aria-label="close"
          onClick={onSettingsClose}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Divider />
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{
          p: 2,
          pb: 0,
        }}
      >
        {t('App:settings.mode.title')}
      </Typography>
      <Box
        sx={{
          p: 2,
          pt: 0,
        }}
      >
        <ToggleButtonGroup
          value={settings.darkMode ? modeSettingValues.dark : modeSettingValues.light}
          exclusive
          onChange={onModeChange}
          aria-label="text alignment"
        >
          <ToggleButton value={modeSettingValues.dark} aria-label="darkmode">
            <DarkModeIcon />
            <Typography variant="body2" component="span">
              {t('App:settings.mode.dark')}
            </Typography>
          </ToggleButton>
          <ToggleButton value={modeSettingValues.light} aria-label="darkmode">
            <LightModeIcon />
            <Typography variant="body2" component="span">
              {t('App:settings.mode.light')}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{
          p: 2,
          pb: 0,
        }}
      >
        {t('App:settings.language.title')}
      </Typography>
      <Box
        sx={{
          p: 2,
          pt: 0,
        }}
      >
        <ToggleButtonGroup
          orientation="vertical"
          value={i18n.language}
          exclusive
          onChange={onLanguageChanged}
        >
          <ToggleButton value={languageSettingValues.german} aria-label="german">
            {t('App:settings.language.german')}
          </ToggleButton>
          <ToggleButton value={languageSettingValues.english} aria-label="english">
            {t('App:settings.language.english')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={settingsOpen}
      onClose={onSettingsClose}
    >
      {darwerList()}
    </Drawer>

  );
};

export default SettingsDrawer;
