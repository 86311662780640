import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Datacenter, Document, DocumentDownloadPreviewIncrement, DocumentDownloadPreviewState,
} from '../../domain/datacenter1';
import { ProgressType } from '../../enums/DocumentDownloadPreview';

export interface DatacenterState {
  datacenterBaseId?: string
  datacenterBaseEntryId?: string
  datacenter?: Datacenter,
  documents: Document[],
  documentDownloadPreviewStates: DocumentDownloadPreviewState[]
}

const initialState: DatacenterState = {
  datacenterBaseId: undefined,
  datacenterBaseEntryId: undefined,
  datacenter: undefined,
  documents: [],
  documentDownloadPreviewStates: new Array<DocumentDownloadPreviewState>(),
};

export const datacenterSlice = createSlice({
  name: 'datacenter',
  initialState,
  reducers: {
    setDatacenterBaseId: (state, action: PayloadAction<string | undefined>) => {
      state.datacenterBaseId = action.payload;
    },
    setDatacenterBaseEntryId: (state, action: PayloadAction<string | undefined>) => {
      state.datacenterBaseEntryId = action.payload;
    },
    setDatacenter: (state, action: PayloadAction<Datacenter | undefined>) => {
      state.datacenter = action.payload;
    },
    setDocuments: (state, action: PayloadAction<Document[]>) => {
      state.documents = action.payload;
    },
    setDocumentDownloadPreviewStates: (state, action: PayloadAction<DocumentDownloadPreviewState[]>) => {
      state.documentDownloadPreviewStates = action.payload;
    },
    upsertDocumentDownloadPreviewState: (state, action: PayloadAction<DocumentDownloadPreviewState>) => {
      const { payload: newDocumentDownloadPreviewState } = action;
      const documentDownloadPreviewState = state.documentDownloadPreviewStates.find(
        (s: DocumentDownloadPreviewState) => s.documentId === newDocumentDownloadPreviewState.documentId,
      );
      if (!documentDownloadPreviewState) {
        state.documentDownloadPreviewStates.push(newDocumentDownloadPreviewState);
      } else {
        documentDownloadPreviewState.loadedPercentage = newDocumentDownloadPreviewState.loadedPercentage;
        documentDownloadPreviewState.progressType = newDocumentDownloadPreviewState.progressType;
      }
    },
    incrementDocumentDownloadPreviewState: (state, action: PayloadAction<DocumentDownloadPreviewIncrement>) => {
      const { payload: documentDownloadPreviewIncrement } = action;
      const documentDownloadPreviewState = state.documentDownloadPreviewStates.find(
        (s: DocumentDownloadPreviewState) => s.documentId === documentDownloadPreviewIncrement.documentId,
      );
      if (!documentDownloadPreviewState) {
        state.documentDownloadPreviewStates.push({
          documentId: documentDownloadPreviewIncrement.documentId,
          loadedPercentage: documentDownloadPreviewIncrement.increment,
          progressType: ProgressType.processing,
        });
      } else {
        documentDownloadPreviewState.loadedPercentage += documentDownloadPreviewIncrement.increment;
      }
    },
    deleteDocumentDownloadPreviewState: (state, action: PayloadAction<string>) => {
      state.documentDownloadPreviewStates = state.documentDownloadPreviewStates.filter(
        (s: DocumentDownloadPreviewState) => s.documentId !== action.payload,
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDatacenterBaseId, setDatacenterBaseEntryId, setDatacenter, setDocuments, setDocumentDownloadPreviewStates, upsertDocumentDownloadPreviewState, deleteDocumentDownloadPreviewState,
  incrementDocumentDownloadPreviewState,
} = datacenterSlice.actions;

export default datacenterSlice.reducer;
