export interface AxiosRequestRejectObj {
  message?: string
  name?: string
}

export default class ApiError {
  identificationType = 'ImAnError';

  public message?: string;

  public axiosRequestRejectObj?: AxiosRequestRejectObj;

  public correlationId?: string;

  constructor(message?: string, axiosRequestRejectObj?: AxiosRequestRejectObj, correlationId?: string) {
    this.message = message;
    this.axiosRequestRejectObj = axiosRequestRejectObj;
    this.correlationId = correlationId;
  }

  getMessage(): string {
    let returnValue = '';
    const { message, axiosRequestRejectObj, correlationId } = this;

    if (message) { returnValue += message; }

    if (axiosRequestRejectObj) {
      const { message: axiosMessage, name } = axiosRequestRejectObj;

      if (name) { returnValue += (returnValue === '' ? '' : ' ') + name; }

      if (axiosMessage) { returnValue += (returnValue === '' ? '' : ' ') + axiosMessage; }
    }

    if (correlationId) {
      returnValue += `${returnValue === '' ? '' : ' '}CorrelationId: ${correlationId}`;
    }

    return returnValue;
  }
}

export function isApiCallError<T>(response: T | ApiError): response is ApiError {
  return (response as ApiError).identificationType === 'ImAnError';
}
