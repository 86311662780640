import { configureStore } from '@reduxjs/toolkit';
import { datacenterApi } from '../services/rtkApis';
import { datacenterReducer } from './slices';

export const store = configureStore({
  reducer: {
    datacenter: datacenterReducer,
    // Add the generated reducer as a specific top-level slice
    [datacenterApi.reducerPath]: datacenterApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(datacenterApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
