import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import { SideMenuEntry } from '../../../domain/application';

export type SideMenuDrawerListItemProps = {
  sideMenuEntry: SideMenuEntry
}

const SideMenuDrawerListItem = ({
  sideMenuEntry,
}: SideMenuDrawerListItemProps): JSX.Element => {
  const { t } = useTranslation('SideMenu', { useSuspense: false });

  const hasLink: boolean = sideMenuEntry.link != null && sideMenuEntry.link !== '';
  const location = useLocation();

  if (!hasLink) {
    return (
      <ListItem button>
        <ListItemIcon>
          {sideMenuEntry.icon}
        </ListItemIcon>
        <ListItemText primary={t(sideMenuEntry.titleResourcekey)} />
      </ListItem>
    );
  }

  const isSelected = location.pathname === sideMenuEntry.link;

  return (
    <ListItemButton
      selected={isSelected}
      component={Link}
      to={sideMenuEntry.link}
    >
      <ListItemIcon>
        {sideMenuEntry.icon}
      </ListItemIcon>
      <ListItemText primary={t(sideMenuEntry.titleResourcekey)} />
    </ListItemButton>
  );
};

export default SideMenuDrawerListItem;
