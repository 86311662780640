import React, { lazy, Suspense } from 'react';
import { PathRouteProps } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';
import RoutesConstants, { getDocumentsPageUrlWithParameter } from '../constants/RoutesConstants';
import { SideMenuEntry } from '../domain/application';

const DocumentsPage = lazy(() => import('./Documents/DocumentsPage'));
const DatacenterSearchPage = lazy(() => import('./DatacenterSearch/DatacenterSearchPage'));
const TestPage = lazy(() => import('./Test/TestPage'));
const NotFoundPage = lazy(() => import('./NotFound/NotFoundPage'));

export function getRouterRoutes(): PathRouteProps[] {
  const routes: Array<PathRouteProps> = [
    {
      path: `${RoutesConstants.routePath.datacenterSearchPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <DatacenterSearchPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.documentsPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <DocumentsPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.testPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <TestPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.testPage2}`,
      element: (
        <Suspense fallback={<>...</>}>
          <TestPage />
        </Suspense>
      ),
    },

    // make sure that not found page is the last one, else it will be shown
    {
      element: (
        <Suspense fallback={<>...</>}>
          <NotFoundPage />
        </Suspense>
      ),
      path: RoutesConstants.routePath.notFoundPage,
    },
  ];
  return routes;
}

export function getSideMenuEntries(datacenterId?: string): SideMenuEntry[] {
  const sideMenuEntries: SideMenuEntry[] = [];

  if (datacenterId == null) {
    sideMenuEntries.push({
      id: 1,
      icon: <SearchIcon />,
      titleResourcekey: 'SideMenu:entries.openDatacenter',
      link: RoutesConstants.routePath.datacenterSearchPage,
    });
  } else {
    sideMenuEntries.push({
      id: 2,
      icon: <ArticleIcon />,
      titleResourcekey: 'SideMenu:entries.documents',
      link: getDocumentsPageUrlWithParameter(datacenterId),
    });
  }

  return sideMenuEntries;
}
